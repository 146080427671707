import { navigate } from 'gatsby';
import { logItem } from '@slices/loggingSlice';
import { useLayoutEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';

const useNavigateLog = ({ questionName = '' }: { questionName: string }) => {
  const sessionId = useAppSelector(e => e?.logging)?.sessionId;
  const { hasStarted } = useAppSelector(e => e?.gameState);
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    // run this on all pages, other than the first
    if (location?.pathname !== '/') {
      if (sessionId && hasStarted) {
        // log which url the user is at
        dispatch(
          logItem({
            question_name: questionName,
            collection_name: 'events',
            event_type: 'navigate',
            location: window?.location?.pathname,
            target: 'nav to',
          }),
        );
      } else if (
        process.env.NODE_ENV !== 'development' &&
        process.env.NODE_ENV !== 'test'
      ) {
        // kick user if no session hash present, and we are not in dev mode
        navigate('/', {
          state: {
            userReset: true,
          },
        });
      }
    }
    // can only run on mount, so I don't care about where
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};

export default useNavigateLog;
