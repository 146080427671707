import React from 'react';
import { m, LazyMotion, domAnimation } from 'framer-motion';

const AnimatedIntroImageWrapper: React.FC = ({ children }) => (
  <LazyMotion features={domAnimation}>
    <m.div
      initial={{
        scale: 0.5,
        alignSelf: 'center',
      }}
      transition={{
        type: 'spring',
        damping: 10,
        stiffness: 500,
        delay: 0.2,
      }}
      animate={{ scale: 1 }}
    >
      {children}
    </m.div>
  </LazyMotion>
);

export default AnimatedIntroImageWrapper;
